import axios from "axios";

import useToast from "@hooks/useToast";
import { useNavigate } from 'react-router-dom'
import { useContext, useState } from "react";
import moment from "moment"
import { AUTH_TOKEN_NAME } from "utility/Constants";

function useApi() {
	// Toast нэмэх функц
	const addToast = useToast()
	const navigate = useNavigate()
	const [token, setToken] = useState(localStorage.getItem(AUTH_TOKEN_NAME) || '')

	const baseURL = process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_API_VERSION || ''
	const source = axios.CancelToken.source();
	const instance = axios.create({
		baseURL: baseURL,
		cancelToken: source.token,
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json",
			'Authorization': `Bearer ${token}`
		},
	});


	instance.interceptors.request.use((req) => {
		req.headers.Authorization = `Bearer ${token}`;
		return req;
	})



	/*
		Response дата
	*/
	instance.interceptors.response.use(
		function (rsp) {
			const data = rsp?.data;
			const text = data?.info

			// хүсэлт амжилттай болоод info ирэх үед
			if (data?.success && text) {
				addToast(
					{
						type: 'success',
						text: text
					}
				)
			}
			else if (!data?.success) {
				// Toast дээр error харуулах
				const err_message = data.error
				if (err_message) {
					addToast(
						{
							type: 'error',
							text: err_message
						}
					)
				}
				// / Toast дээр warn харуулах
				console.log(data)
				const warn_message = data.warn
				if (warn_message) {
					addToast(
						{
							type: 'warning',
							text: warn_message
						}
					)
				}
			}
			return data

		},
		function (error) {
			var err = error?.response?.data
			var status_code = error?.response?.status
			// Token
			if (err?.refresh) {
				navigate('/login')
				return err
			}

			if (err) {
				if ([403, 401].indexOf(status_code) !== -1) {
					navigate('/login')
				} else {
					addToast(
						{
							text: 'Алдаа гарлаа. Системийн админд хандана уу.',
							type: 'error',
							style: {
								padding: '16px',
								border: `1px solid #EB544C`
							}
						})
				}
				return err
			}
			else {
				return Promise.reject(err);
			}
		}
	);

	/** Datatable parameter-ийн хувьсагч нар */
	const dataTableQuery = (limit, page, sort, search, searchCount, other) => {
		if (other) {
			let params = ""
			other.forEach(p => {
				params += `&${p.name}=${p.value}`
			})
			return `page=${page}&limit=${limit}&sorting=${sort}${search}&search=${searchCount}${params}`
		}
		return `page=${page}&limit=${limit}&sorting=${sort}${search}&search=${searchCount}`
	}

	return {
		core: {
		},
		geoms: {
			getGeom: (id, config) => instance.get(`/geom/get-geom/${id}?config=${config}`),
			getAirGeom: () => instance.get(`/geom/air-road/`),

			salbar: {
				getGeom: () => instance.get(`/geom/salbar`),
			},
			angi: {
				getGeom: () => instance.get(`/geom/angi`),
			},
			stants: {
				getAll: () => instance.get(`/geom/stants`)
			},
			getBvsGeom: () => instance.get(`/geom/bvs`),

			// Аймгийн мэдээлэл
			aimag: {
				get: () => instance.get(`/geom/aimag`),
			},
			// Сумын мэдээлэл
			sum: {
				get: (aimag) => instance.get(`/geom/sum?aimag=${aimag}`),
			},
			// Багийн мэдээлэл
			bag: {
				get: (sum) => instance.get(`/geom/bag?sum=${sum}`),
			},
		},

		org: {
			getOrgByType: (type) => instance.get(`/org/by-type/${type}`),
			salbar: {
				getList: (...args) => instance.get(`/org/salbar/list/?${dataTableQuery(...args)}`),
				post: data => instance.post(`/org/salbar`, data),
				put: (data, pk) => instance.put(`/org/salbar/${pk}`, data),
				delete: (pk) => instance.delete(`/org/salbar/${pk}`),

				getAll: () => instance.get(`/org/salbar`),
				getBySubOrg: (subOrgId) => instance.get(`/org/salbar/by-suborg/${subOrgId}`),
				getOne: (pk) => instance.get(`/org/salbar/${pk}`),
				getWeather: () => instance.get(`/org/salbar/weather`),
				postStants: (data, pk) => instance.post(`/org/salbar/post-stants/${pk}`, data),

				// getByAngi: () => instance.get(`/org/salbar/by-angi`),

			},
			mainOrg: {
				getAll: () => instance.get(`/org/main-org/`),
				getList: (...args) => instance.get(`/org/main-org/list/?${dataTableQuery(...args)}`),
				post: data => instance.post(`/org/main-org/`, data),
				put: (data, pk) => instance.put(`/org/main-org/${pk}`, data),
				delete: (pk) => instance.delete(`/org/main-org/${pk}`),
			},
			angi: {
				getList: (...args) => instance.get(`/org/angi/list/?${dataTableQuery(...args)}`),
				postStants: (data, pk) => instance.post(`/org/angi/post-stants/${pk}`, data),
				getAll: () => instance.get(`/org/angi/`),
				post: data => instance.post(`/org/angi/`, data),
				put: (data, pk) => instance.put(`/org/angi/${pk}`, data),
				delete: (pk) => instance.delete(`/org/angi/${pk}`),
			},
		},

		//  Хэрэглэгчийн мэдээлэл
		user: {
			auth: (data) => instance.post(`/user/auth/`, data),

			logged: () => instance.get('/user/logged/'),
			logout: (pk) => instance.get(`/user/logout/${pk}`),

			post: data => instance.post(`/user/`, data),
			put: (data, pk) => instance.put(`/user/${pk}`, data),
			delete: (pk) => instance.delete(`/user/${pk}`),

			positionAuth: (data) => instance.post(`/user/position-auth/`, data),

			workEmployee: {
				getByDate: (date, salbar_code) => instance.get(`/user/work-employee/by-date?date=${date}&salbar_code=${salbar_code}`)
			},

			getListWithFilter: (...args) => instance.get(`/user/list/with-filter/?${dataTableQuery(...args)}`),

		},
		combatOperationNote: {
			getBySalbar: (pk) => instance.get(`/cmn/combat-operation-note/by-salbar/${pk}`),
			post: data => instance.post(`/cmn/combat-operation-note`, data),
			getOne: (pk) => instance.get(`/cmn/combat-operation-note/${pk}`),
			put: (data, pk) => instance.put(`/cmn/combat-operation-note/${pk}`, data),
			delete: (pk) => instance.delete(`/cmn/combat-operation-note/${pk}`),
		},
		rlsSchedule: {
			getBySalbar: (pk) => instance.get(`/cmn/rls-schedule/by-salbar/${pk}`),
			post: data => instance.post(`/cmn/rls-schedule`, data),
			getOne: (pk) => instance.get(`/cmn/rls-schedule/${pk}`),
			put: (data, pk) => instance.put(`/cmn/rls-schedule/${pk}`, data),
			delete: (pk) => instance.delete(`/cmn/rls-schedule/${pk}`),
		},
		dutyMembers: {
			checked: {
				getBySalbar: (pk) => instance.get(`/cmn/duty-members-checked/by-salbar/${pk}`),
				post: data => instance.post(`/cmn/duty-members-checked`, data),
				getOne: (pk) => instance.get(`/cmn/duty-members-checked/${pk}`),
				put: (data, pk) => instance.put(`/cmn/duty-members-checked/${pk}`, data),
				delete: (pk) => instance.delete(`/cmn/duty-members-checked/${pk}`),
			},
			result: {
				getBySalbar: (pk) => instance.get(`/cmn/duty-members-result/by-salbar/${pk}`),
				post: data => instance.post(`/cmn/duty-members-result`, data),
				getOne: (pk) => instance.get(`/cmn/duty-members-result/${pk}`),
				put: (data, pk) => instance.put(`/cmn/duty-members-result/${pk}`, data),
				delete: (pk) => instance.delete(`/cmn/duty-members-result/${pk}`),
			},
		},
		tacticSign: {
			getAll: () => instance.get(`/tactic-sign`),
			getList: (...args) => instance.get(`/tactic-sign/list/?${dataTableQuery(...args)}`),
			post: data => instance.post(`/tactic-sign`, data),
			getOne: (pk) => instance.get(`/tactic-sign/${pk}`),
			put: (data, pk) => instance.put(`/tactic-sign/${pk}`, data),
			delete: (pk) => instance.delete(`/tactic-sign/${pk}`),
		},
		stantsSign: {
			getAll: () => instance.get(`/stants-sign`),
			getList: (...args) => instance.get(`/stants-sign/list/?${dataTableQuery(...args)}`),
			post: data => instance.post(`/stants-sign`, data),
			getOne: (pk) => instance.get(`/stants-sign/${pk}`),
			put: (data, pk) => instance.put(`/stants-sign/${pk}`, data),
			delete: (pk) => instance.delete(`/stants-sign/${pk}`),
		},
		stants: {
			getList: (...args) => instance.get(`/stants/list/?${dataTableQuery(...args)}`),
			getAll: () => instance.get(`/stants`),
			getOne: (pk) => instance.get(`/stants/${pk}`),
			put: (data, pk) => instance.put(`/stants/${pk}`, data),
			delete: (pk) => instance.delete(`/stants/${pk}`),
		},
		bvs: {
			getOne: (pk) => instance.get(`/bvs/${pk}`),
			getAll: () => instance.get(`/bvs`),
			getList: (...args) => instance.get(`/bvs/list/?${dataTableQuery(...args)}`),
			post: data => instance.post(`/bvs`, data),
			put: (data, pk) => instance.put(`/bvs/${pk}`, data),
			delete: (pk) => instance.delete(`/bvs/${pk}`),
		},
		scheduleTime: {
			getAll: () => instance.get(`/time`),
			getOne: (pk) => instance.get(`/time/${pk}`),
			post: data => instance.post(`/time`, data),
			put: (data, pk) => instance.put(`/time/${pk}`, data),
			delete: (pk) => instance.delete(`/time/${pk}`),
		},

		schedule: {
			post: (user_id, start, end) => instance.post(`/schedule/estimate?start=${start}&end=${end}`, user_id),
			get: (start, end) => instance.get(`/schedule?start=${start}&end=${end}`),
			getTk: (start, end) => instance.get(`/schedule/tk?start=${start}&end=${end}`),
			getKomand: (start, end) => instance.get(`/schedule/estimate?start=${start}&end=${end}`),
			setTimetable: (datas) => instance.post(`/schedule/habituate`, datas),
			getTimetable: () => instance.get(`/schedule/habituate`),
			delete: (pk) => instance.delete(`/schedule/habituate/${pk}`),

			// Төлөвлөгөөт бусаар салбар зогсоох
			stopEvent: (data) => instance.post('/schedule/not-plan/stop', data),
			// Төлөвлөгөөт бусаар салбар ажиллуулах
			workEvent: (data) => instance.post('/schedule/not-plan/work', data),

			getReport: (start, end) => instance.get(`/schedule/report?start=${start}&end=${end}`)

		},
		morse: {
			get: () => instance.get(`/cmn/morse`),
			getOne: (pk) => instance.get(`/cmn/morse/${pk}`),
			post: data => instance.post(`/cmn/morse`, data),
			put: (pk, data) => instance.put(`/cmn/morse/${pk}`, data),
			delete: (pk) => instance.delete(`/cmn/morse/${pk}`),
			transaction: (pk, data, type) => instance.put(`/cmn/morse/transaction/${pk}?type=${type}`, data),
			getBySalbar: (pk) => instance.get(`/cmn/morse/by-salbar/${pk}`),
		},


		degree: {
			getList: (...args) => instance.get(`/degree/list/?${dataTableQuery(...args)}`),
			getAll: () => instance.get(`/degree`),
			post: data => instance.post(`/degree`, data),
			put: (data, pk) => instance.put(`/degree/${pk}`, data),
			delete: (pk) => instance.delete(`/degree/${pk}`),
		},
		signal: {
			post: data => instance.post(`/signal/`, data),
			put: (data, pk) => instance.put(`/signal/${pk}`, data),
			delete: (pk) => instance.delete(`/signal/${pk}`),

			getOne: (pk) => instance.get(`/signal/${pk}`),
			getAll: (pk) => instance.get(`/signal`),

			getList: (...args) => instance.get(`/signal/list/?${dataTableQuery(...args)}`)
		},
		target: {
			// ?salbar_code=${salbar_code}&target_code=${target_code}
			getListByDate: (date = moment(new Date()).format("YYYY-MM-DD")) => instance.get(`/cmn/target/list?date=${date}`)
		},
		weather: {
			getAllCurrent: (id) => instance.get(`/cmn/weather-info/current`),

			getByAngi: (id) => instance.get(`/cmn/weather-info/by-angi/${id}`),
			getBySalbar: (id) => instance.get(`/cmn/weather-info/by-salbar/${id}`),
			getByDate: (date, org_code) => instance.get(`/cmn/weather-info/by-date?date=${date}&org_code=${org_code}`),
			post: (data) => instance.post(`/cmn/weather-info/`, data)
		},
		chemistry: {
			getByDate: (date, org_code) => instance.get(`/cmn/chemistry-info/by-date?date=${date}&org_code=${org_code}`),
			post: (data) => instance.post(`/cmn/chemistry-info/`, data)
		},
		radiation: {
			getByDate: (date, org_code) => instance.get(`/cmn/radiation-info/by-date?date=${date}&org_code=${org_code}`),
			post: (data) => instance.post(`/cmn/radiation-info/`, data)
		},
		dayInfo: {
			get: () => instance.get(`/cmn/day-info/`),
			getOne: (id) => instance.get(`/cmn/day-info/${id}`),
			post: (datas) => instance.post(`/cmn/day-info/`, datas)
		},
		dayWorkInfo: {
			get: () => instance.get(`/cmn/day-work-info/`),
			getOne: (id) => instance.get(`/cmn/day-work-info/${id}`),
			post: (datas) => instance.post(`/cmn/day-work-info/`, datas)
		},
		report: {
			operator: {
				getByDate: () => instance.get(`/cmn/report-info/operator/`),
				post: (datas) => instance.post(`/cmn/report-info/operator/`, datas),
				put: (pk, datas) => instance.put(`/cmn/report-info/operator/${pk}/`, datas),
				changeStatus: (pk, status) => instance.patch(`/cmn/report-info/operator/${pk}/`, status),
				delete: (pk) => instance.delete(`/cmn/report-info/operator/${pk}/`),
			},
			plansheet: {
				getByDate: () => instance.get(`/cmn/report-info/plansheet/`),
				post: (datas) => instance.post(`/cmn/report-info/plansheet/`, datas),
				put: (pk, datas) => instance.put(`/cmn/report-info/plansheet/${pk}/`, datas),
				changeStatus: (pk, status) => instance.patch(`/cmn/report-info/plansheet/${pk}/`, status),
				delete: (pk) => instance.delete(`/cmn/report-info/plansheet/${pk}/`),
			},
			ubj: {
				getByDate: () => instance.get(`/cmn/report-info/ubj/`),
				getSalbarReports: () => instance.get(`/cmn/report-info/ubj/salbar-reports/`)
				// getAllSubReports: () => instance.get(`/cmn/report-info/ubj/`)
			}
		}

	}
}

export default useApi;
