import { createContext, useState, useEffect } from "react";

// hooks imports
import { useNavigate } from "react-router-dom";
import useApi from "@hooks/useApi";
import { AUTH_TOKEN_NAME } from "utility/Constants";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {

    const [token, setToken] = useState(() =>
        localStorage.getItem(AUTH_TOKEN_NAME)
            ? localStorage.getItem(AUTH_TOKEN_NAME)
            : ''
    );


    const [workEmployee, setWorkEmployee] = useState({});
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const api = useApi().user

    async function logoutUser() {
        localStorage.removeItem(AUTH_TOKEN_NAME)
        const { success, data } = await api.logout(workEmployee?.id)
        if(success){
            setWorkEmployee({})
            navigate('/login')
        }
    }
    console.log(workEmployee)
    async function getUser() {
        if (!token) {
            navigate('login')
            return
        }
        setLoading(true)
        const { success, data } = await api.logged()
        if (success) {
            setWorkEmployee(data)
        } else {
            navigate('/login')
        }
        setLoading(false)
    }


    const contextData = {
        loading,
        workEmployee,
        setWorkEmployee,
        logoutUser,
    };
    useEffect(() => {
        getUser()
    }, [])


    return (
        <AuthContext.Provider value={contextData}>
            {loading ? null : children}
        </AuthContext.Provider>
    );
};
